import { Box, VStack } from '@chakra-ui/react';
import { FC } from 'react';
import { ParagraphCustomTeaserListFragment } from '../../../generated/types';
import EbikeAtlasTeaser from '../../Common/EbikeAtlasTeaser';
import Wrapper from '../Wrapper';
import Container from '../../Layout/Container';

interface CustomTeaserListProps {
  data: ParagraphCustomTeaserListFragment;
  isInArticleLayout?: boolean;
}

const CustomTeaserList: FC<CustomTeaserListProps> = ({
  data,
  isInArticleLayout,
}) => {
  const teaserProps = isInArticleLayout
    ? {
        imageContainerMaxWidth: '320px',
        noOfLines: 3,
        hideCTA: true,
      }
    : {};

  const Inner = (
    <Box width="100%" className="ParagraphCustomTeaserList">
      <VStack justifyContent="flex-start" columns={1} spacing={8}>
        {data.items!.map((teaser, i) => (
          <EbikeAtlasTeaser
            key={i}
            text={teaser.body}
            title={teaser.title}
            url={teaser.linkUrl}
            image={teaser.image.teaserLarge?.url}
            {...teaserProps}
          />
        ))}
      </VStack>
    </Box>
  );

  return (
    <Wrapper className="ParagraphCustomTeaserListWrapper" width="100%">
      {isInArticleLayout ? Inner : <Container>{Inner}</Container>}
    </Wrapper>
  );
};

export default CustomTeaserList;
